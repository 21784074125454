import { showErrorNotification, showSuccessNotification } from '@/app/components/shared/notifications';
import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { useMutation } from '@tanstack/react-query';

export const useConvertMoreFormats = (assetId: number, { onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
    mutationFn: (formats: string[]) => cloudApiClient.post(`/rawmodel/${assetId}/addFormats`, { formats }),
    onSuccess: () => {
      onSuccess();
      showSuccessNotification({ message: 'Scheduling processing additional formats was successful.' });
    },
    onError: (error) => {
      showErrorNotification({ message: error.message });
    },
  });
};
