import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { useQuery } from '@tanstack/react-query';

export const useProcessedFormatsQuery = ({ assetId, pollingEnabled }: { assetId: number; pollingEnabled: boolean }) => {
  return useQuery({
    queryKey: [PROCESSED_FORMATS_KEY_BASE, assetId],
    queryFn: async () => {
      const { data } = await cloudApiClient.get<ConverterJob[]>(`/rawmodel/${assetId}/processedFormats`);

      const inProgress = data.filter((job) => job.status !== 'finished' && job.status !== 'failed');

      const progress =
        inProgress.length === 0
          ? 0
          : inProgress.reduce((acc, next) => (acc = acc > next.progress ? next.progress : acc), 100);

      const failedJobsCount = data.filter((job) => job.status === 'failed').length;

      return {
        inProgress: inProgress.map((job) => job.formats).flat(),
        progress,
        failedJobsCount,
      };
    },
    refetchInterval: pollingEnabled ? 3000 : undefined,
    throwOnError: false,
  });
};

const PROCESSED_FORMATS_KEY_BASE = 'processedFormats';

interface ConverterJob {
  id: number;
  formats: string[];
  status: string;
  progress: number;
}
