import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Checkbox, Group, Text } from '@mantine/core';
import { RowActionsColumn } from '@/app/components/shared/table/columns/RowActionsColumn';
import { PipelineInfoColumn } from '@/app/assets/components/table/columns/PipelineInfoColumn';
import { BasicInfoColumn } from '@/app/assets/components/table/columns/basicInfoColumn/BasicInfoColumn';
import { FormatColumn } from '@/app/assets/components/table/columns/FormatColumn';
import { UploadedAtColumn } from '@/app/assets/components/table/columns/UploadedAtColumn';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { OptimisticAssetsAction } from '@/app/assets/components/table/hooks/useOptimisticAssets';
import { AssetActionColumn } from '@/app/assets/components/table/columns/assetActionsColumn/AssetActionColumn';
import { TableHeader } from '@/app/components/shared/table/columns/TableHeader';
import { OptionalText } from '@/app/components/shared/table/OptionalText';
import { getSize } from '../utils/getSize';
import { getFormattedNumber } from '../utils/getFormattedNumber';
import { useSingleRawAssetDownload } from '@/app/assets/components/table/columns/assetActionsColumn/hooks/useSingleRawAssetDownload';
import { useFileDownload } from '@/app/components/shared/fileDownload';
import { CONVERTER_ROW } from './useAssetsTable';

export const ASSET_COLUMNS_SPAN = 40;

export const useColumns = (
  disabled: boolean,
  onAssetDelete?: (action: OptimisticAssetsAction) => void,
  onlyOutputs = false
) => {
  const columnHelper = useMemo(() => createColumnHelper<AssetRow>(), []);
  const { onDownload: onDownloadRawAsset } = useSingleRawAssetDownload();
  const { downloadZip } = useFileDownload();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'actions',
        meta: { span: 3 },
        cell: ({ row }) => <RowActionsColumn wrap='nowrap' row={row} justify='flex-end' />,
        header: ({ table }) => {
          const handleSelectiveRowSelection = () => {
            const allCoreRowsChecked = table.getCoreRowModel().rows.every((row) => row.getIsSelected());

            table.getCoreRowModel().rows.forEach((row) => {
              row.toggleSelected(!allCoreRowsChecked);

              row.subRows.forEach((subRow) => {
                subRow.toggleSelected(false);

                subRow.subRows.forEach((subSubRow) => {
                  subSubRow.toggleSelected(false);
                });
              });
            });
          };
          return (
            <Group justify='flex-end'>
              <Checkbox
                {...{
                  disabled: disabled,
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: handleSelectiveRowSelection,
                }}
              />
            </Group>
          );
        },
      }),
      columnHelper.accessor('basicInfo', {
        meta: { span: 16, handleOnClick: true },
        cell: ({ row, getValue }) =>
          getValue().rowType === 'pipeline' ? (
            <PipelineInfoColumn
              {...{
                row,
                name: getValue().name,
                outputsCount: getValue().outputsCount,
              }}
            />
          ) : (
            <BasicInfoColumn item={getValue()} row={row} useOnlyOutputs={onlyOutputs} />
          ),
        header: (_) => <TableHeader title='Name' />,
      }),
      columnHelper.accessor('formats', {
        meta: { span: 6, handleOnClick: true },
        cell: (row) => (
          <FormatColumn
            assetId={row.row.original.basicInfo.id}
            formats={row.getValue()}
            onDownload={async (format, filename = '') => {
              const assetRow = row.row.original;
              if (assetRow.basicInfo.rowType === 'raw') {
                return onDownloadRawAsset(assetRow.basicInfo.id);
              } else if (assetRow.basicInfo.rowType === CONVERTER_ROW || assetRow.basicInfo.rowType === 'pbrOutput') {
                let fileToDownload = assetRow.actionsData?.downloadFiles?.find((file) =>
                  file.orgName?.endsWith(format)
                );

                // If no exact match is found, try to find a file that matches the format with a '.zip' extension
                if (!fileToDownload) {
                  fileToDownload = assetRow.actionsData?.downloadFiles?.find((file) =>
                    file.orgName?.endsWith(`${format}.zip`)
                  );
                }

                if (fileToDownload) {
                  return downloadZip([{ url: fileToDownload.url, name: fileToDownload.exportName }]);
                }
              } else {
                // RapidModel
                // Try to find a file to download that matches the filename exactly
                let fileToDownload = assetRow.actionsData?.downloadFiles?.find((file) => file.orgName === filename);

                // If no exact match is found, try to find a file that matches the format with a '.zip' extension
                if (!fileToDownload) {
                  fileToDownload = assetRow.actionsData?.downloadFiles?.find((file) =>
                    file.orgName?.endsWith(`${format}.zip`)
                  );
                }

                if (fileToDownload) {
                  let downloadName = fileToDownload.exportName;

                  // Add zip extension if orgName ends with .zip is true for gltf, obj and usd
                  if (fileToDownload.orgName?.endsWith('.zip')) {
                    downloadName = `${downloadName}.zip`;
                  }

                  return downloadZip([{ url: fileToDownload.url, name: downloadName }]);
                }
              }
            }}
          />
        ),
        header: (_) => <TableHeader title='format' ta='center' />,
      }),
      columnHelper.accessor('polygons', {
        meta: { span: 4, handleOnClick: true },
        cell: (row) => <OptionalText value={getFormattedNumber(row.getValue())} px={4} ta='left' />,
        header: (_) => <TableHeader title='polygons' ta='left' />,
      }),
      columnHelper.accessor('size', {
        meta: { span: 3, handleOnClick: true },
        cell: (row) => <OptionalText value={getSize(row.getValue())} ta='left' />,
        header: (_) => <TableHeader title='size' ta='center' />,
      }),
      columnHelper.accessor('createdAt', {
        meta: { span: 4, handleOnClick: true },
        cell: (row) => <UploadedAtColumn uploadedAt={row.getValue()} />,
        header: (_) => <TableHeader title={onlyOutputs ? 'Created at' : 'Uploaded at'} ta='center' />,
      }),
      columnHelper.display({
        meta: { span: 4 },
        id: 'assetActions',
        cell: ({ row }) => <AssetActionColumn row={row} onAssetDelete={onAssetDelete} />,
        header: () => (
          <Text fw={600} c='dark.2' tt='uppercase' ta='center'>
            Actions
          </Text>
        ),
      }),
    ],
    [columnHelper, disabled, downloadZip, onAssetDelete, onDownloadRawAsset, onlyOutputs]
  );
  return { columns, columnsSpan: ASSET_COLUMNS_SPAN };
};
