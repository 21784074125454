import {
  ApiRawModel,
  GetRawModelResponse,
  RawModel,
  RawModelPbr,
  RawModelPreview,
  UpdateRawModel,
} from '@/types/rawModel';
import { Page } from '@/types/pagination';
import { getRapidModels } from '@/app/assets/actions/rapidModel';
import { getFileContentsAsList, getMetadata, getRpdInfo, rawModelPendingStates } from '@/app/assets/actions/utils';
import { getSearchQuery } from '@/utils/getSearchQuery';
import { getCombinedDownloads } from './downloads';
import { isCad } from '@/utils/fileUtils';
import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { AxiosError } from 'axios';
import { getImpersonation } from '@/utils/getImpersonation';

export const getRawModelInPendingStates = async function (searchTerm?: string, page?: number) {
  const { data: responseData } = await cloudApiClient.get<GetRawModelResponse>(
    `rawmodel${getSearchQuery(searchTerm, page)}`
  );

  return responseData.data.filter((r) => rawModelPendingStates.has(r.upload_status));
};

const getDownloads = async (model: ApiRawModel) => {
  return await getCombinedDownloads(model.id);
};

export const getRawModels = async function (searchTerm?: string, page?: number): Promise<Page<RawModel>> {
  const { isImpersonating, email } = getImpersonation();

  const searchQuery = getSearchQuery(searchTerm, page);
  const params = searchQuery ? searchQuery + '&withOutputModels=true' : '?withOutputModels=true';

  const { data: responseData } = await (isImpersonating
    ? cloudApiClient.post<GetRawModelResponse>(`rawmodel${params}`, {
        impersonatedEmail: email,
      })
    : cloudApiClient.get<GetRawModelResponse>(`rawmodel${params}`));

  const rawModels = await Promise.all(
    responseData.data.map(async (rawModel): Promise<RawModel> => {
      const [rpdInfo, rapidModels, warnings, errors, additionalDownloads] = await Promise.all([
        getRpdInfo({
          downloads: rawModel.downloads,
        }),
        getRapidModels(rawModel),
        getFileContentsAsList(rawModel.downloads?.['warning.log']),
        getFileContentsAsList(rawModel.downloads?.['error.log']),
        getDownloads(rawModel),
      ]);

      return {
        ...rawModel,
        rpdInfo,
        rapidModels,
        assetType: 'raw',
        scale_factor: parseFloat(rawModel.scale_factor),
        errors,
        warnings,
        additionalDownloads,
        metadata: getMetadata(rawModel.metadata),
      };
    })
  );

  return {
    data: rawModels,
    currentPage: responseData.meta.current_page,
    pageSize: responseData.meta.per_page,
    totalPages: responseData.meta.last_page,
  };
};

export const deleteRawModels = async function (ids: number[]) {
  await cloudApiClient.post('rawmodel/delete', { ids });
};

export const updateRawModel = async function (id: number, rawModel: UpdateRawModel) {
  await cloudApiClient.put(`rawmodel/${id}`, rawModel);
};

export const deleteRawModelTag = async function (rawModelId: number, tagId: number) {
  await cloudApiClient.delete(`rawmodel/${rawModelId}/tags/${tagId}`);
};

export const getRawModelPreview = async function (rawModelId: number): Promise<RawModelPreview | null> {
  try {
    const response = await cloudApiClient.get<RawModelPreview>(`rawmodel/${rawModelId}/preview`);
    return response.data;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status == 404) {
      return null;
    }
    throw e;
  }
};

export const getPbrRawModelDownloads = async function (id: number) {
  const { isImpersonating, email } = await getImpersonation();
  const response = await cloudApiClient.get<RawModelPbr>(
    `rawmodel/${id}/downloads/pbr${isImpersonating ? `?impersonatedEmail=${encodeURIComponent(email)}` : ''}`
  );

  return response.data;
};
