import { Badge } from '@mantine/core';

export const AvailableFormatToConvert = ({
  format,
  isSelected,
  isPending,
  onClick,
}: {
  format: string;
  isSelected: boolean;
  isPending: boolean;
  onClick: () => void;
}) => {
  return (
    <Badge
      tt='lowercase'
      fw='normal'
      style={{ cursor: 'pointer', userSelect: 'none' }}
      onClick={onClick}
      color={isSelected || isPending ? 'teal' : 'gray.2'}
      radius='md'
      className={isPending ? 'animate-pulse' : ''}
    >
      .{format}
    </Badge>
  );
};
