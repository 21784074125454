import { cloudApiClient } from '@/services/clientSideCloudApiService';
import { ApiRawModel } from '@/types/rawModel';
import { useQuery } from '@tanstack/react-query';

const SINGLE_RAW_ASSET_QUERY_KEY = 'rawAsset';
export const useRawAssetQuery = ({ assetId, pollingEnabled }: { assetId: number; pollingEnabled: boolean }) => {
  return useQuery({
    queryKey: [SINGLE_RAW_ASSET_QUERY_KEY, assetId],
    queryFn: async () => {
      const { data } = await cloudApiClient.get<{ data: ApiRawModel }>(`/rawmodel/${assetId}`);
      return data.data;
    },
    refetchInterval: pollingEnabled ? 3000 : undefined,
    throwOnError: false,
  });
};
