import { RawModelUploadStatus } from '@/types/rawModel';
import { rawModelFinishedStates, rawModelSuccessStates } from '@/app/assets/actions/utils';
import { ActionsDropdownWrapper } from '@/app/assets/components/table/columns/assetActionsColumn/ActionsDropdown';
import DownloadItem from './items/DownloadItem';
import DeleteItem from './items/DeleteItem';
import { Button, Group } from '@mantine/core';
import { IconBolt } from '@tabler/icons-react';

export type AssetActionsColumnProps = {
  onDownload: () => void;
  onDelete: () => void;
  onProcess: () => void;
  status?: RawModelUploadStatus;
};

const rawModelStatusActionMap: Record<'delete' | 'download' | 'process', Set<RawModelUploadStatus>> = {
  process: rawModelSuccessStates,
  delete: rawModelFinishedStates,
  download: rawModelSuccessStates,
};

export function RawAssetActionsColumn({ onDownload, onDelete, onProcess, status }: AssetActionsColumnProps) {
  if (!status || !rawModelFinishedStates.has(status)) {
    return null;
  }

  const isProcessorAvailable = rawModelStatusActionMap.process.has(status);
  const isDeleteAvailable = rawModelStatusActionMap.delete.has(status);
  const isDownloadAvailable = rawModelStatusActionMap.download.has(status);

  return (
    <Group wrap='nowrap' gap={6}>
      {isProcessorAvailable && (
        <Button bg='teal.0' radius={36} w={40} p={0} style={{ border: 'none' }} onClick={onProcess}>
          <IconBolt width={20} stroke={1} />
        </Button>
      )}
      <ActionsDropdownWrapper>
        <DownloadItem onDownload={onDownload} isAvailable={isDownloadAvailable} />
        <DeleteItem onDelete={onDelete} isAvailable={isDeleteAvailable} />
      </ActionsDropdownWrapper>
    </Group>
  );
}
