import { Group, Loader, Text } from '@mantine/core';
import { RawModelUploadStatus } from '@/types/rawModel';
import { rawModelPendingStates } from '@/app/assets/actions/utils';

interface UploadStatusProps {
  status: RawModelUploadStatus | undefined;
  isBeingUploaded: boolean;
}

export function UploadStatus({ status, isBeingUploaded }: UploadStatusProps) {
  if (!status || (!rawModelPendingStates.has(status) && !isBeingUploaded)) {
    return null;
  }

  return (
    <Group gap='xs' wrap='nowrap'>
      <Loader color='teal' size='sm' />
      <Text size='xs' c='gray.6' style={{ textTransform: 'capitalize' }}>
        {isBeingUploaded ? 'Uploading' : status}
      </Text>
    </Group>
  );
}
