import Cookies from 'js-cookie';

type ImpersonationReturnType =
  | {
      email: string;
      isImpersonating: true;
    }
  | {
      email: undefined;
      isImpersonating: false;
    };

export const getImpersonation = (): ImpersonationReturnType => {
  const impersonationEmail = Cookies.get(IMPERSONATION_COOKIE_NAME);

  if (impersonationEmail) {
    return {
      email: impersonationEmail,
      isImpersonating: true,
    };
  }

  return {
    email: undefined,
    isImpersonating: false,
  };
};

export const IMPERSONATION_COOKIE_NAME = 'impersonateEmail';
