import { Badge } from '@mantine/core';
import { AssetRow } from '../../hooks/types';
import { showErrorNotification } from '@/app/components/shared/notifications';
import { getBlob } from '@/app/components/shared/fileDownload/utils';

export const ConvertedFormat = ({ format, asset }: { format: string; asset: AssetRow }) => {
  return (
    <Badge
      style={{ cursor: 'pointer', userSelect: 'none' }}
      tt='lowercase'
      color='gray.2'
      fw='normal'
      radius='md'
      onClick={() => download(format, asset)}
    >
      .{format}
    </Badge>
  );
};

const download = async (format: string, asset: AssetRow) => {
  let fileToDownload = asset.actionsData?.downloadFiles?.find((file) => file.orgName?.endsWith(format));

  if (!fileToDownload) {
    fileToDownload = asset.actionsData?.downloadFiles?.find((file) => file.orgName?.endsWith(`${format}.zip`));
  }

  if (!fileToDownload) {
    return showErrorNotification({ message: 'File not found' });
  }

  const blob = await getBlob(fileToDownload.url, (e) => console.log(e));
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileToDownload.exportName;
  a.click();
  a.remove();
};
