import { Text } from '@mantine/core';
import { deleteAsset, downloadFile } from '@/app/assets/components/table/utils/assetActions';
import { RawAssetActionsColumn } from '@/app/assets/components/table/columns/assetActionsColumn/RawAssetActionsColumn';
import { OutputAssetActionsColumn } from '@/app/assets/components/table/columns/assetActionsColumn/OutputAssetActionsColumn';
import { AssetRow } from '@/app/assets/components/table/hooks/types';
import { Row } from '@tanstack/table-core';
import { useConfirmationModal } from '@/app/components/modal/ConfirmationModalContext';
import { OptimisticAssetsAction } from '@/app/assets/components/table/hooks/useOptimisticAssets';
import { useFileDownload } from '@/app/components/shared/fileDownload';
import { useSingleRawAssetDownload } from '@/app/assets/components/table/columns/assetActionsColumn/hooks/useSingleRawAssetDownload';
import { useRowSelection } from '@/app/assets/contexts/RowSelectionContext';
import { useRunWorkflow } from '@/app/assets/contexts/RunWorkflowContext';
import { showErrorNotification, showSuccessNotification } from '@/app/components/shared/notifications';
import { useMutation } from '@tanstack/react-query';
import { publishOneClickEmbed, PublishResponse } from '@/app/assets/actions/create-one-click';
import { useClipboard } from '@mantine/hooks';
import { useEffect, useRef } from 'react';
import { getImpersonation } from '@/utils/getImpersonation';
import { CONVERTER_ROW } from '../../hooks/useAssetsTable';

interface AssetActionColumnProps {
  row: Pick<Row<AssetRow>, 'original' | 'getIsSelected' | 'toggleSelected'>;
  onAssetDelete?: (action: OptimisticAssetsAction) => void;
  showShare?: boolean;
}

export function AssetActionColumn({ row, onAssetDelete, showShare }: AssetActionColumnProps) {
  const { showConfirmationModal } = useConfirmationModal();
  const { downloadZip } = useFileDownload();
  const { onDownload: onDownloadRawAsset } = useSingleRawAssetDownload();

  const { setRowSelection, clearRowSelection } = useRowSelection();
  const { open: openWorkflowModal } = useRunWorkflow();

  const { copy, copied, reset } = useClipboard({ timeout: 8000 });
  const prevRowId = useRef(row.original.basicInfo.id);

  useEffect(() => {
    if (prevRowId.current !== row.original.basicInfo.id) {
      reset();
    }
    prevRowId.current = row.original.basicInfo.id;
  }, [row.original.basicInfo.id, reset, prevRowId]);

  const { mutate: publish, isPending } = useMutation({
    mutationFn: async ({ modelId, modelName }: { modelId: number; modelName: string }) => {
      const config = {
        enableInspectorControl: true,
      };

      const body = {
        model_id: modelId,
        save_user_theme: false,
        config: JSON.stringify(config),
        name: `Shared ${modelName}`,
      };

      const publishEmbedResponse = await publishOneClickEmbed(body);
      copy(publishEmbedResponse.embed_url);
      return publishEmbedResponse;
    },
    onSuccess: (response: PublishResponse) => {
      showSuccessNotification({
        message: 'Link copied to your clipboard.',
      });
    },
    onError: () => {
      showErrorNotification({
        title: 'Failed to generate link.',
        message: 'Something went wrong. Please try again',
      });
    },
  });

  const onDelete = async () => {
    const { isImpersonating } = getImpersonation();
    if (isImpersonating) {
      return showErrorNotification({
        title: 'Not allowed',
        message: 'You cannot delete assets while impersonating another user.',
      });
    }
    const confirmed = await showConfirmationModal({
      action: 'Delete',
      content: <Text>Do you really want to delete {row.original.basicInfo.name}? This action cannot be undone.</Text>,
      title: 'Confirm',
    });
    if (!confirmed) {
      return;
    }
    if (row.getIsSelected()) {
      row.toggleSelected(false);
    }
    onAssetDelete?.({
      action: 'remove',
      payload: [row.original.basicInfo.id],
    });
    await deleteAsset(row.original);
  };

  const onProcess = async () => {
    const { isImpersonating } = getImpersonation();
    if (isImpersonating) {
      return showErrorNotification({
        title: 'Not allowed',
        message: 'You cannot run presets while impersonating another user.',
      });
    }
    // Unselect all other models and only select the current row
    clearRowSelection();
    // As we also call this function from the sidebar, construct a row selection object ourselves, as we do not have the row reference there
    const picked = ((row) => ({
      formats: row.original.formats,
      type: row.original.basicInfo.rowType,
      id: row.original.basicInfo.id,
    }))(row);
    setRowSelection({
      [JSON.stringify(picked)]: true,
    });

    openWorkflowModal();
  };

  const onShare = () => {
    publish({
      modelId: row.original.basicInfo.id,
      modelName: row.original.basicInfo.name,
    });
  };

  if (row.original.basicInfo.rowType === 'raw') {
    return (
      <RawAssetActionsColumn
        status={row.original.uploadStatus}
        onDelete={onDelete}
        onProcess={onProcess}
        onDownload={() => onDownloadRawAsset(row.original.basicInfo.id, `${row.original.basicInfo.name}.zip`)}
      />
    );
  }

  const onDownload = () =>
    row.original.actionsData!.downloadFiles!.length === 1
      ? downloadFile({
          downloadUrl: row.original.actionsData!.downloadFiles![0].url,
          id: row.original.basicInfo.id,
        })
      : downloadZip(
          row.original.actionsData!.downloadFiles!.map((f) => ({ name: f.exportName, orgName: f.orgName, url: f.url })),
          undefined,
          row.original.basicInfo.rowType === 'output'
        );

  if (
    row.original.basicInfo.rowType === 'output' ||
    row.original.basicInfo.rowType === CONVERTER_ROW ||
    row.original.basicInfo.rowType === 'pbrOutput'
  ) {
    return (
      <OutputAssetActionsColumn
        showShare={showShare}
        onDownload={onDownload}
        onDelete={onDelete}
        onShare={onShare}
        shareIsPending={isPending}
        shareIsCopied={copied}
        asset={row.original}
        isAddMoreAvailable={row.original.basicInfo.rowType === CONVERTER_ROW}
      />
    );
  }
}
