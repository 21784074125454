import { AxiosProgressEvent } from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { getBlob } from '@/app/components/shared/fileDownload/utils';
import { useThrottledCallback } from 'use-debounce';

export type Progress = {
  progress: number;
  finishedFiles: number;
  totalFiles: number;
  files: Record<string, number>;
};

export const useZipDownload = () => {
  const [progress, setProgress] = useState<Progress>({
    progress: 0,
    finishedFiles: 0,
    totalFiles: 0,
    files: {},
  });
  const [isPending, setIsPending] = useState(false);

  const throttledProgressUpdate = useThrottledCallback((progressEvent: AxiosProgressEvent, name: string) => {
    setProgress((s) => {
      const files = {
        ...s.files,
        ...{ [name]: progressEvent.progress ?? 0 },
      };

      return {
        ...s,
        files,
        finishedFiles: s.finishedFiles + (progressEvent.progress === 1 ? 1 : 0),
        progress: Object.values(files).reduce((acc, next) => acc + next, 0) / s.totalFiles,
      };
    });
  }, 300);

  const download = async (
    files: { name: string; orgName?: string; url: string }[],
    downloadName?: string,
    addZipExtension?: boolean
  ) => {
    setIsPending(true);
    setProgress({
      finishedFiles: 0,
      progress: 0,
      files: {},
      totalFiles: files.length,
    });

    try {
      if (files.length > 1) {
        const zip = new JSZip();

        await Promise.all(
          files.map(async ({ name, orgName, url }) => {
            const blob = await getBlob(url, (e) => throttledProgressUpdate(e, name));
            // for rawmodels we don't need to add .zip extension
            let zipName = name;
            if (addZipExtension && orgName?.endsWith('.zip')) {
              // for outputs/rapidmodels and the original file name we need to add .zip extension
              zipName = `${name}.zip`;
            }
            zip.file(zipName, blob);
          })
        );
        const zipped = await zip.generateAsync({ type: 'blob' });
        saveAs(zipped, downloadName ?? 'download.zip');
      } else {
        const blob = await getBlob(files[0].url, (e) => throttledProgressUpdate(e, files[0].name));

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = files[0].name;
        a.click();
      }
    } finally {
      setIsPending(false);
    }
  };

  return { isPending, progress, download };
};
